import React from 'react';
import NavigationItem from './navigationItem/navigationItem';

const navigationItems = (props) => (
    <ul className='navigationItems'>
        <NavigationItem {...props} url='/prodotti'>Prodotti</NavigationItem>
        <NavigationItem {...props} url='/servizi'>Servizi</NavigationItem>
        <NavigationItem {...props} url='/storia'>Storia</NavigationItem>
        <NavigationItem {...props} url='/contatti'>Contatti</NavigationItem>
    </ul>
);

export default navigationItems;