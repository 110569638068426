import React from 'react';

const burgerMenu = (props) => {
  return (
      <div onClick={props.toggle} className={`burger ${props.active ? 'active' : ''}`}>
        <div className='burger__container'>
          <div className='burger__line'></div>
          <div className='burger__line'></div>
        </div>
      </div>
  );
};

export default burgerMenu;
