import React from 'react';
import { NavLink } from 'react-router-dom';


const navigationItem = (props) => ( 
    <li onClick={props.canceled} className='navigationItem'>
        <NavLink className='navigationItem__link' exact={props.exact} to={props.url}>{props.children}</NavLink>
    </li>
);

export default navigationItem;