import React, {Component, Suspense} from 'react';
import Header from './containers/header/header';
import Footer from './components/footer/footer';
import {Switch, Route} from 'react-router-dom';
import CookieBanner from "./components/cookieBanner/cookieBanner";
const Prodotti = React.lazy(() => import('./containers/prodotti/prodotti'));
const Servizi = React.lazy(() => import('./containers/servizi/servizi'));
const Storia = React.lazy(() => import('./containers/storia/storia'));
const Home = React.lazy(() => import('./containers/home/home'));
const Contatti = React.lazy(() => import('./containers/contatti/contatti'));

class App extends Component {

    state = {
        consent: null
    };

    componentDidMount() {
        const consent = this.getCookie('consent');
        if (consent === 'true') {
            this.setState({
                consent: true
            })
        } else {
            this.setState({
                consent: false
            })
        }
    }

    getCookie = (name) => {
        const regex = new RegExp(`(?:(?:^|.*;\\s*)${name}\\s*\\=\\s*([^;]*).*$)|^.*$`);
        return decodeURIComponent(document.cookie.replace(regex, '$1'));
    };

    acceptCookies = () => {
        const date = new Date();
        date.setFullYear(date.getFullYear() + 1);
        const utcString = date.toUTCString();
        document.cookie = `consent=true;expires=${utcString}`;
        this.setState({
            consent: true
        });
    };

    render() {
        return (
            <div className="app">
                <CookieBanner onAcceptCookies={this.acceptCookies} class={this.state.consent !== false ? 'cookie-banner--hidden' : ''} />
                <Header />
                <Switch>
                    <Route path='/prodotti'
                           render={() => <Suspense fallback={<div>In caricamento...</div>}><Prodotti/></Suspense>}>
                    </Route>
                    <Route path='/servizi'
                           render={() => <Suspense fallback={<div>In caricamento...</div>}><Servizi/></Suspense>}>
                    </Route>
                    <Route path='/storia'
                           render={() => <Suspense fallback={<div>In caricamento...</div>}><Storia/></Suspense>}>
                    </Route>
                    <Route path='/contatti'
                           render={() => <Suspense fallback={<div>In caricamento...</div>}><Contatti/></Suspense>}>
                    </Route>
                    <Route path='/'
                           render={() => <Suspense fallback={<div>In caricamento...</div>}><Home/></Suspense>}>
                    </Route>
                </Switch>
                <Footer />
            </div>
        );
    }
}

export default App;
