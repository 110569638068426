import React, { Component, Fragment } from 'react';
import BurgerMenu from '../../components/burgerMenu/burgerMenu';
import NavigationItems from '../navigation/navigationItems/navigationItems';
import SideDrawer from '../navigation/sideDrawer/sideDrawer';
import BackDrop from '../../components/ui/backdrop/backdrop';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { withRouter } from "react-router";

class Header extends Component {

    state = {
        burgerMenuActive: false
    };

    componentDidMount() {
        // 2. Get a target element that you want to persist scrolling for (such as a modal/lightbox/flyout/nav).
        // Specifically, the target element is the one we would like to allow scroll on (NOT a parent of that element).
        // This is also the element to apply the CSS '-webkit-overflow-scrolling: touch;' if desired.
        this.targetElement = document.querySelector('#sidedrawer-nav .navigationItems');
    }

    componentDidUpdate() {
        console.log('component updated!');
        if (this.state.burgerMenuActive) {
            disableBodyScroll(this.targetElement);
        } else {
            enableBodyScroll(this.targetElement);
        }
    }

    toggleBurger = () => {
        const burgerMenuActive = this.state.burgerMenuActive;
        this.setState({ burgerMenuActive: !burgerMenuActive });
    };

    goToHome = () => {
        this.setState({ burgerMenuActive: false });
        this.props.history.push('./');
    };

    componentWillUnmount() {
        // 5. Useful if we have called disableBodyScroll for multiple target elements,
        // and we just want a kill-switch to undo all that.
        // OR useful for if the `hideTargetElement()` function got circumvented eg. visitor
        // clicks a link which takes him/her to a different page within the app.
        clearAllBodyScrollLocks();
    }

    render() {
        return (
            <Fragment>
                <header className='header'>
                    <BurgerMenu active={this.state.burgerMenuActive} toggle={this.toggleBurger} />
                    <span onClick={this.goToHome} className='icon-logo'>
					</span>
                    <nav className='desktopOnly navigation'>
                        <NavigationItems exact={true} />
                    </nav>
                </header>
                <SideDrawer open={this.state.burgerMenuActive} canceled={this.toggleBurger} />
                <BackDrop show={this.state.burgerMenuActive} canceled={this.toggleBurger} />
            </Fragment>
        )
    }
}

export default withRouter(Header);