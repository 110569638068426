import React, {Component} from 'react';
import properties from '../../properties/properties';

class CookieBanner extends Component {

    render() {
        return (
            <div className={['cookie-banner', this.props.class].join(' ')}>
                <h3>{properties.cookies.title}</h3>
                <span className='cookie-banner__text'>{properties.cookies.text}</span>
                <button className='button__element' onClick={this.props.onAcceptCookies}>OK</button>
            </div>
        );
    }
}

export default CookieBanner;
