import React, {Fragment} from 'react';
import NavigationItems from '../navigationItems/navigationItems';

const sideDrawer = (props) => {
    return (
        <Fragment>
            <div className={props.open? 'sidedrawer open' : 'sidedrawer close'}>
                <nav id='sidedrawer-nav' className='sidedrawer__nav'>
                    <NavigationItems {...props} />
                </nav>                
            </div>
        </Fragment>
    )
}

export default sideDrawer;