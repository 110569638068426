import React from 'react';
import properties from '../../properties/properties'

const footer = (props) => (
    <footer className='footer'>
        <div className='footer__col'>
            <h3 className='footer__title'>{properties.footer.contattiTitolo}</h3>
            <p className='footer__par'>{properties.footer.contattiNome}</p>
            <p className='footer__par'>{properties.footer.contattiVia}</p>
            <p className='footer__par'>{properties.footer.contattiCittà}</p>
            <p className='footer__par'>{properties.footer.contattiPaese}</p>
            <p className='footer__par'>{properties.footer.contattiMail}</p>
            <p className='footer__par'>{properties.footer.contattiTel}</p>
        </div>
        <div className='footer__col'>
            <h3 className='footer__title'>{properties.footer.legaliTitolo}</h3>
            <p className='footer__par'>{properties.footer.legaliIVA}</p>
            <p className='footer__par'>{properties.footer.legaliProprietà}</p>
        </div>
    </footer>
)

export default footer;